import Vue from 'vue'
import 'whatwg-fetch'
import 'regenerator-runtime/runtime'
import store from '@/store'
import moment from 'moment'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router'
import auth from './authorization'
import downloader from './download'
import { createProvider } from './vue-apollo'
import 'chartist/dist/chartist.min.css'
require('moment/locale/fr')
import BusinessHours from 'vue-business-hours';
import i18n from './plugins/i18n';

moment.locale(localStorage.getItem('lang') || 'fr');

Vue.config.productionTip = false;

Vue.use(auth);

Vue.use(downloader);

Vue.use(require('vue-moment'), { moment })

Vue.use(require('vue-chartist'));

Vue.use(BusinessHours);


Vue.filter('capitalize', function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
});


Vue.filter('formatDate', function (date, format) {
  return moment(date).isValid()
    ? moment(date).format(format)
    : '';
});

Vue.filter('formatFloat', function (n, decimals, mandatoryDecimals) {
  if (decimals === 0) return Math.round(n);
  const pow = Math.pow(10, decimals);
  let result = `${Math.round(n * pow)/ pow}`;

  let decimalsResult = result.split('.')[1] || '';
  if (decimalsResult.length > decimals) {
    decimalsResult = decimalsResult.slice(0,2);
  }
  if (mandatoryDecimals && decimalsResult.length < mandatoryDecimals) {
    decimalsResult = `${decimalsResult}${new Array((mandatoryDecimals - decimalsResult.length) + 1).join('0')}`
  }
  return decimalsResult
    ? `${result.split('.')[0]}.${decimalsResult}`
    : result;
});


export const instance = new Vue({
  vuetify,
  i18n,
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app');
