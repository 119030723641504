import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import i18n from './i18n';
import rowsPerPage from '../tools/pagination';

import '@mdi/font/css/materialdesignicons.css';
import fr from 'vuetify/lib/locale/fr';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

VDataFooter.extendOptions.props.itemsPerPageOptions.default = () => rowsPerPage;

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    themes: {
      light: {
        primary: '#C3002e',
        nissan: '#C3002e',
        secondary: '#00cd00',
      },
    },
  },
  lang: {
    locales: { fr, en },
    current: i18n.locale, 
    t: (key, ...params) => i18n.t(key, params),
  },
});