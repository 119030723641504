import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/shops/menu',
      name: 'menu',
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/Menu.vue')
    },
    {
      path: '/shops/cgv',
      name: 'shops-CGV',
      meta: {
        authorization: {
          EDIT_CGV: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/CGV.vue')
    },
    {
      path: '/shops/grouped-list',
      name: 'CGV',
      meta: {
        authorization: {
          MANAGE_SHOP_GROUPED: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/grouped-list.vue')
    },
    {
      path: '/config-suppliers',
      name: 'config-suppliers',
      meta: {
        authorization: {
          SUPPLIER_QUALITY_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/aftermarket/SuppliersQualityConfig.vue')
    },
    {
      path: '/config-suppliers-prices',
      name: 'config-suppliers-prices',
      meta: {
        authorization: {
          SUPPLIER_PRICES_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/aftermarket/PricesConfiguration.vue')
    },
    {
      path: '/config-customer-type',
      name: 'config-customer-type',
      meta: {
        authorization: {
          CUSTOMER_TYPE_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/aftermarket/CustomerTypesConfig.vue')
    },
    {
      path: '/shops/groupes',
      name: 'groups',
      meta: {
        authorization: {
          MANAGE_GROUPS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/Groups.vue')
    },
    {
      path: '/shops/advanced-discount',
      name: 'shops-advanced-discount',
      meta: {
        authorization: {
          SHOPS_ADVANCED_DISCOUNT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/AdvancedDiscount.vue')
    },
    {
      path: '/marketing/advanced-discount-coupon',
      name: 'marketing-advanced-discount-coupon',
      meta: {
        authorization: {
          MARKETING_ADVANCED_DISCOUNT_COUPON: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/AdvancedDiscountCoupon/index.vue')
    },
    {
      path: '/marketing/advanced-discount-coupon/make-promo',
      name: 'make-marketing-advanced-discount-coupon',
      meta: {
        authorization: {
          MARKETING_ADVANCED_DISCOUNT_COUPON: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/AdvancedDiscountCoupon/make-promo.vue')
    },
    {
      path: '/marketing/advanced-discount-coupon/make-promo/:id',
      name: 'update-marketing-advanced-discount-coupon',
      meta: {
        authorization: {
          MARKETING_ADVANCED_DISCOUNT_COUPON: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/AdvancedDiscountCoupon/make-promo.vue')
    },
    {
      name: 'Stepper',
      path: '/product/mapping',
      component: () => import(/* webpackChunkName: "about" */'../views/product/Stepper'),
    },
    {
      name: 'StepperCategories',
      path: '/product/make-mapping-categories',
      component: () => import(/* webpackChunkName: "about" */'../views/product/StepperCategories'),
    },
    {
      name: 'AddNewPriorityRule',
      path: '/product/priority-rule',
      meta: {
        authorization: {
          PRIORITY_PRODUCT: true
        }
      },

      component: () => import(/* webpackChunkName: "about" */'../views/product/AddNewPriorityRule'),
    },
    {
      name: 'AddNewPriorityRuleV2',
      path: '/product/priority-rule-v2',
      meta: {
        authorization: {
          PRIORITY_PRODUCT: true
        }
      },

      component: () => import(/* webpackChunkName: "about" */'../views/product/AddNewPriorityRuleV2'),
    },
    {
      name: 'ListMappingProduct',
      path: '/product/mapping-product-list',
      meta: {
        authorization: {
          MAPPING_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/ListMappingProduct.vue'),
    },
    {
      name: 'MappingModele',
      path: '/product/mapping-modeles',
      meta: {
        authorization: {
          MAPPING_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/MappingModele.vue'),
    },
    {
      name: 'MappingCategory',
      path: '/product/mapping-categories',
      meta: {
        authorization: {
          MAPPING_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/MappingCategories.vue'),
    },
    {
      name: 'ListOrderProduct',
      path: '/product/order-product-list',
      meta: {
        authorization: {
          PRIORITY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/ListOrderProduct.vue'),
    },
    {
      name: 'ListOrderProductV2',
      path: '/product/order-product-list-v2',
      meta: {
        authorization: {
          PRIORITY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/ListOrderProductV2.vue'),
    },
    {
      name: 'Categories',
      path: '/product/categories',
      meta: {
        // authorization: {
        //   PRIORITY_PRODUCT: true
        // }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/categories/list-categories.vue'),
    },
    {
      name: 'childrenCategories',
      path: '/product/categories/:id',
      meta: {
        authorization: {
          PRODUCT_CATEGORIES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/categories/list-categories.vue'),
    },
    {
      name: 'make-Category',
      path: '/product/make-category',
      meta: {
        authorization: {
          PRODUCT_CATEGORIES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/categories/make-category.vue'),
    },
    {
      name: 'edit-Category',
      path: '/product/make-category/:id_category',
      meta: {
        authorization: {
          PRODUCT_CATEGORIES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/categories/make-category.vue'),
    },
    {
      path: '/mandat',
      name: 'mandat',
      component: () => import(/* webpackChunkName: "about" */ '../views/B2B/mandat/Mandat.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        public: true
      },
      component: () => import(/* webpackChunkName: "about" */ '../components/LoginPage')
    },
    {
      path: '/users/password/:token',
      name: 'password',
      meta: {
        public: true
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/user/Password.vue')
    },
    {
      path: '/',
      name: 'home',
      meta: {
        authorization: {
          SPID_HOME: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    },
    {
      path: '/product/list-product-scrapper',
      name: 'ListProduitsScrapper',
      meta: {
        authorization: {
          SCRAPPING_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/product/ListProduitsScrapper'),
    },
    {
      path: '/product/suppliers-list',
      name: 'suppliersList',
      meta: {
        authorization: {
          MANAGE_SUPPLIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/supplier/SuppliersList.vue'),
    },
    {
      path: '/product/make-supplier',
      name: 'makeSupplier',
      meta: {
        authorization: {
          MANAGE_SUPPLIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/supplier/MakeSupplier.vue'),
    },
    {
      path: '/product/make-supplier/:id',
      name: 'editSupplier',
      meta: {
        authorization: {
          MANAGE_SUPPLIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */'../views/supplier/MakeSupplier.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
    },
    {
      path: '/commande',
      name: 'commande',
      meta: {
        authorization: {
          ORDER_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/order/OrderList.vue')
    },
    {
      path: '/carts',
      name: 'carts',
      meta: {
        authorization: {
          CART_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/cart/CartList.vue')
    },
    {
      path: '/commande/:data_source/:id_order',
      name: 'order',
      redirect: { name: 'b2border' },
      // meta: {
      //   authorization: {
      //     ORDER_LIST: true
      //   }
      // },
      component: () => import(/* webpackChunkName: "about" */ '../views/order/OrderFull.vue')
    },
    {
      path: '/order/:data_source/:id_order',
      name: 'b2border',
      // meta: {
      //   authorization: {
      //     B2B_ORDER_LIST: true
      //   }
      // },
      component: () => import(/* webpackChunkName: "about" */ '../views/order/OrderFull.vue')
    },
    {
      path: '/reports/sales',
      name: 'reports-sales',
      meta: {
        authorization: {
          MONTHLY_REPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/B2B/ReportSales.vue')
    },
    {
      path: '/reports/qualification',
      name: 'report-qualification',
      meta: {
        authorization: {
          STOCK_QUALIFICATION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/qualification/qualification-report.vue')
    },
    {
      path: '/reports/marketing',
      name: 'report-marketing',
      meta: {
        authorization: {
          MARKETING_REPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/report/marketing-report.vue')
    },
    {
      path: '/reports/traffic',
      name: 'report-traffic',
      meta: {
        authorization: {
          TRAFFIC_REPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/report/traffic-report.vue')
    },
    {
      path: '/marketing/templates-list',
      name: 'templates-list',
      meta: {
        authorization: {
          MARKETING_REPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/report/tpl-templates-list.vue')
    },
    {
      path: '/reports/campaign-marketing',
      name: 'campaign-marketing',
      meta: {
        authorization: {
          MARKETING_REPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/new-campaigns/campaignReport.vue')
    },
    {
      path: '/rapports',
      name: 'rapports',
      meta: {
        authorization: {
          ORDER_REPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/Reports.vue')
    },
    {
      path: '/shop-sellers',
      name: 'shop-sellers',
      meta: {
        authorization: {
          SHOP_SELLER_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopSellers.vue')
    },
    {
      path: '/unsubscribed-report-config',
      name: 'unsubscribed-report-config',
      meta: {
        authorization: {
          CONTACT_UNSUB_REPORT_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/contact/unsub_report_config.vue')
    },
    {
      path: '/history/:type/:id',
      name: 'history',
      meta: {
        authorization: {
          MANAGE_HISTORY: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/history/List.vue')
    },
    {
      path: '/shops/config',
      name: 'shops-config',
      meta: {
        authorization: {
          SHOPS_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/ShopsConfig.vue')
    },
    {
      path: '/shops/create',
      name: 'createShop',
      meta: {
        authorization: {
          LIST_SHOPS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/Create.vue')
    },
    {
      path: '/b2b/shops/:idShop',
      name: 'editShop-b2b',
      meta: {
        authorization: {
          LIST_SHOPS_B2B: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/Edit.vue')
    },
    {
      path: '/marketing/dossier/:id_dossier/conversation/:id_sms_conversation',
      name: 'dossier-details',
      meta: {
        authorization: {
          DOSSIERS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/sms/sms-conversation-details.vue')
    },
    {
      path: '/shops/:idShop',
      name: 'editShop',
      meta: {
        authorization: {
          LIST_SHOPS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/Edit.vue')
    },
    {
      path: '/b2b/shops',
      name: 'shops-b2b',
      meta: {
        authorization: {
          LIST_SHOPS_B2B: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/b2b/List.vue')
    },
    {
      path: '/shops',
      name: 'shops',
      meta: {
        authorization: {
          LIST_SHOPS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/List.vue')
    },
    {
      path: '/configuration',
      name: 'Configuration',
      meta: {
        authorization: {
          CONFIGURATION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/configuration/configuration_interface.vue')
    },
    {
      path: '/order/:data_source/:id_order/chat/:id_session',
      name: 'chat',
      // meta: {
      //   authorization: {
      //     NISSAN_DEALER_LIST: true
      //   }
      // },
      component: () => import(/* webpackChunkName: "about" */ '../views/chat/chat.vue')
    },
    {
      path: '/nissan-dealer-list',
      name: 'nissan-dealer-list',
      meta: {
        authorization: {
          NISSAN_DEALER_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/nissan/ListDealer.vue')
    },
    {
      path: '/kia-dealer-list',
      name: 'kia-dealer-list',
      meta: {
        authorization: {
          KIA_DEALER_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/kia/ListDealers.vue')
    },
    {
      path: '/suzuki-dealer-list',
      name: 'suzuki-dealer-list',
      meta: {
        authorization: {
          SUZUKI_DEALER_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/suzuki/ListDealers.vue')
    },
    {
      path: '/test-import',
      name: 'test-import',
      meta: {
        authorization: {
          TEST_IMPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/TestImport.vue')
    },
    {
      path: '/test-import-grc',
      name: 'test-import-grc',
      meta: {
        authorization: {
          TEST_IMPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/import/test-import-grc.vue')
    },
    {
      path: '/mecaplannig',
      name: 'config-mecaplannig',
      meta: {
        authorization: {
          CONFIG_MECAPLANNING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/Mecaplanning.vue')
    },
    {
      path: '/mapping-import',
      name: 'mapping-import',
      meta: {
        authorization: {
          TEST_IMPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/mappingImport.vue')
    },
    {
      path: '/config-import',
      name: 'config-import',
      meta: {
        authorization: {
          CONFIG_IMPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/ConfigImport.vue')
    },
    {
      path: '/config-import-grc',
      name: 'config-import-grc',
      meta: {
        authorization: {
          CONFIG_IMPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/import/config-import-grc.vue')
    },
    {
      path: '/config-stock',
      name: 'config-stock',
      meta: {
        authorization: {
          CONFIG_STOCK: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/ConfigImportStock.vue')
    },
    {
      path: '/config-stock-v2',
      name: 'config-stock-v2',
      meta: {
        authorization: {
          CONFIG_STOCK: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/ConfigImportStockV2.vue')
    },
    {
      path: '/config-meca-planning',
      name: 'config-meca-planning',
      meta: {
        authorization: {
          CONFIG_MECA_PLANNING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/ConfigMecaPlanning.vue')
    },
    {
      path: '/satatistique-interface',
      name: 'satatistique-interface',
      meta: {
        authorization: {
          STATISTIQUE_INTERFACE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/StatInterface.vue')
    },
    {
      path: '/shop-interface',
      name: 'shop-interface',
      meta: {
        authorization: {
          SHOP_INTERFACE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/shop/shops-interfaces.vue')
    },
    {
      path: '/stock-list',
      name: 'stock-list',
      meta: {
        authorization: {
          STOCK_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/StockList.vue')
    },
    {
      path: '/stock-list-v2',
      name: 'stock-list-v2',
      meta: {
        authorization: {
          STOCK_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/StockListV2.vue')
    },
    {
      path: '/stock/list-stock-qualification-configs',
      name: 'list-stock-qualification',
      meta: {
        authorization: {
          STOCK_QUALIFICATION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/qualification/list-qualification-configs.vue')
    },
    {
      path: '/stock/make-stock-qualification-config',
      name: 'make-stock-qualification-config',
      meta: {
        authorization: {
          STOCK_QUALIFICATION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/qualification/make-qualification-config.vue')
    },
    {
      path: '/test-stock-import',
      name: 'test-stock-import',
      meta: {
        authorization: {
          TEST_STOCK_IMPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/TestStockImport.vue')
    },
    {
      path: '/test-stock-import-v2',
      name: 'test-stock-import-v2',
      meta: {
        authorization: {
          TEST_STOCK_IMPORT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/TestStockImportV2.vue')
    },
    {
      path: '/promotion-config',
      name: 'promotion-config',
      meta: {
        authorization: {
          PROMOTION_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/ConfigPromotion.vue')
    },
    {
      path: '/promotion-config-2',
      name: 'promotion-config-2',
      meta: {
        authorization: {
          PROMOTION_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/ConfigPromotion2.vue')
    },
    {
      path: '/promotion-config-2-v2',
      name: 'promotion-config-2-v2',
      meta: {
        authorization: {
          PROMOTION_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/ConfigPromotion2-V2.vue')
    },
    {
      path: '/promotions-to-apply',
      name: 'promotions-to-apply',
      meta: {
        authorization: {
          PROMOTION_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/PromotionsToApply.vue')
    },
    {
      path: '/unknown-products',
      name: 'unknown-products',
      meta: {
        authorization: {
          MANAGE_UNKNOWN_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stock/UnknownProducts.vue')
    },
    {
      path: '/parts/products',
      name: 'parts-products',
      meta: {
        authorization: {
          PARTS_PRODUCTS_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/parts/products.vue')
    },
    {
      path: '/parts/reference',
      name: 'parts-reference',
      meta: {
        authorization: {
          PARTS_PRODUCTS_REFERENCE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/parts/reference.vue')
    },
    {
      path: '/parts/edit-product/:id_product',
      name: 'edit-parts-products',
      meta: {
        authorization: {
          UPDATE_PARTS_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../components/parts/update-product.vue')
    },
    {
      path: '/parts/add-product',
      name: 'add-product',
      meta: {
        authorization: {
          PARTS_PRODUCTS_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../components/parts/add-product.vue')
    },
    {
      path: '/parts/generic_elements',
      name: 'generic_elements',
      meta: {
        authorization: {
          PARTS_UPDATE_GENERIC_ELEMENTS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/parts/generic-elements.vue')
    },
    {
      path: '/template-campaign',
      name: 'template-campaign',
      meta: {
        authorization: {
          TEMPLATE_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/campaign/ConfigTemplate.vue')
    },
    {
      path: '/pending-campaign',
      name: 'pending-campaign',
      meta: {
        authorization: {
          PENDING_CAMPAIGN: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/PendingCampaign.vue')
    },

    {
      path: '/pending-manual-campaign',
      name: 'pending-manual-campaign',
      meta: {
        authorization: {
          CUSTOM_CAMPAIGN: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/new-campaigns/campaign-manager/pending-manual-campaigns.vue')
    },
    {
      path: '/campaigns-manager',
      name: 'campaigns-manager',
      meta: {
        authorization: {
          CUSTOM_CAMPAIGN: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/new-campaigns/campaign-manager/manual-campaigns-manager.vue')
    },
    {
      path: '/contacts-campaigns',
      name: 'contacts-campaigns',
      meta: {
        authorization: {
          CUSTOM_CAMPAIGN: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/new-campaigns/contact-campaign/manual-campaigns-clients.vue')
    },
    {
      path: '/campaigns-manager/:id_custom_campaign',
      name: 'campaigns-manager-id',
      meta: {
        // authorization: {
        //   CAMPAIGN_STAT: true
        // }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/new-campaigns/campaign-manager/manual-campaigns-manager.vue')
    },
    {
      path: '/campaign-stat',
      name: 'campaign-stat',
      meta: {
        authorization: {
          CAMPAIGN_STAT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/campaign/CampaignStats.vue')
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        authorization: {
          LIST_USERS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/user/User.vue')
    },
    {
      path: '/fast-order',
      name: 'fast-order',
      meta: {
        authorization: {
          FAST_ORDER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/order/FastOrderList.vue')
    },
    {
      path: '/billing-account',
      name: 'billing-account',
      meta: {
        authorization: {
          MANAGE_BILLING_ACCOUNTS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/Account.vue')
    },
    {
      path: '/billing-account/success',
      name: 'billing-account-mandate-success',
      meta: {
        authorization: {
          MANAGE_BILLING_ACCOUNTS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/MandateSuccess.vue')
    },

// B2B
    {
      path: '/orders',
      name: 'b2b-orders',
      meta: {
        authorization: {
          B2B_ORDER_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/B2B/order/OrderList.vue')
    },
    {
      path: '/payouts/:idAccount?/:idPeriod?',
      name: 'b2b-payouts',
      meta: {
        authorization: {
          B2B_PAYOUT_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/B2B/payout/PayoutList.vue')
    },
    {
      path: '/onboarding-form',
      name: 'nissan-onboarding-form',
      meta: {
        authorization: {
          NISSAN_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/nissan/OnboardingForm.vue')
    },
    {
      path: '/onboarding-kia-form',
      name: 'kia-onboarding-form',
      meta: {
        authorization: {
          KIA_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/kia/OnboardingForm.vue')
    },
    {
      path: '/onboarding-suzuki-form',
      name: 'suzuki-onboarding-form',
      meta: {
        authorization: {
          SUZUKI_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/suzuki/OnboardingForm.vue')
    },
    {
      path: '/faq-nissan',
      name: 'faq-nissan',
      meta: {
        authorization: {
          NISSAN_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/nissan/FAQ.vue')
    },
    {
      path: '/faq-kia',
      name: 'faq-kia',
      meta: {
        authorization: {
          KIA_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/kia/FAQ.vue')
    },
    {
      path: '/faq-suzuki',
      name: 'faq-suzuki',
      meta: {
        authorization: {
          SUZUKI_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/suzuki/FAQ.vue')
    },
    {
      path: '/get-more-nissan',
      name: 'get-more-nissan',
      meta: {
        authorization: {
          NISSAN_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/nissan/GetMore.vue')
    },
    {
      path: '/get-more-kia',
      name: 'get-more-kia',
      meta: {
        authorization: {
          KIA_ONBOARDING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/kia/GetMore.vue')
    },
    {
      path: '/commissions-summary/:idAccount?/:idPeriod?',
      name: 'b2b-commissions-summary',
      meta: {
        authorization: {
          B2B_COMMISSIONS_SUMMARY: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/B2B/order/CommissionsSummary.vue')
    },
    {
      path: '/invoice-files',
      name: 'invoice-files',
      meta: {
        authorization: {
          INVOICE_FILES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/InvoiceFiles.vue')
    },
    {
      path: '/companies',
      name: 'companies',
      meta: {
        authorization: {
          MANAGE_COMPANY: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/Companies.vue')
    },
    {
      path: '/make-company',
      name: 'make-company',
      meta: {
        authorization: {
          MANAGE_COMPANY: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/CompanyMake.vue')
    },
    {
      path: '/make-company/:id_company',
      name: 'edit-company',
      meta: {
        authorization: {
          MANAGE_COMPANY: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/CompanyMake.vue')
    },

    {
      path: '/subscriptions',
      name: 'subscriptions',
      meta: {
        authorization: {
          MANAGE_BILLING_SUBSCRIPTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/Subscriptions.vue')
    },
    {
      path: '/make-subscription',
      name: 'make-subscription',
      meta: {
        authorization: {
          MANAGE_BILLING_SUBSCRIPTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/SubscriptionMake.vue')
    },
    {
      path: '/make-subscription/:id',
      name: 'edit-subscription',
      meta: {
        authorization: {
          MANAGE_BILLING_SUBSCRIPTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/SubscriptionMake.vue')
    },
    {
      path: '/subscription/:id',
      name: 'subscription-details',
      meta: {
        authorization: {
          MANAGE_BILLING_SUBSCRIPTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/Subscription.vue')
    },
    {
      path: '/billing-conditions',
      name: 'billing-conditions',
      meta: {
        authorization: {
          MANAGE_BILLING_CONDITION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/BillingConditions.vue')
    },
    {
      path: '/billing-products',
      name: 'billing-products',
      meta: {
        authorization: {
          MANAGE_BILLING_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/BillingProducts.vue')
    },
    {
      path: '/billing-tax',
      name: 'billing-tax',
      meta: {
        authorization: {
          MANAGE_BILLING_TAX: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/BillingTax.vue')
    }, {
      path: '/billing-static',
      name: 'billing-static',
      meta: {
        authorization: {
          BILLING_STATISTICS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/BillingStats.vue')
    },
    {
      path: '/seo/home',
      name: 'seo-home-edit',
      meta: {
        authorization: {
          MANAGE_SEO: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/seo/HomePSEdit.vue')
    },
    {
      path: '/seo/feature/:idFeature',
      name: 'seo-feature-edit',
      meta: {
        authorization: {
          MANAGE_SEO: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/seo/FeatureEdit.vue')
    },
    {
      path: '/seo/features-edit',
      name: 'seo-features-edit',
      meta: {
        authorization: {
          MANAGE_SEO: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/seo/FeaturesEdit.vue')
    },
    {
      path: '/seo/meta/:dataSource',
      name: 'seo-meta',
      meta: {
        authorization: {
          MANAGE_SEO: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/seo/Meta.vue')
    },
    {
      path: '/seo/categories-edit',
      name: 'seo-categories-edit',
      meta: {
        authorization: {
          MANAGE_SEO: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/seo/CategoriesEdit.vue')
    },
    {
      path: '/content/banner',
      name: 'content-banner',
      meta: {
        authorization: {
          MANAGE_BANNER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/banner/Banner.vue')
    },
    {
      path: '/content/make-banner',
      name: 'content-make-banner',
      meta: {
        authorization: {
          MANAGE_BANNER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/banner/BannerMake.vue')
    },
    {
      path: '/content/make-banner/:id_banner',
      name: 'content-edit-banner',
      meta: {
        authorization: {
          MANAGE_BANNER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/banner/BannerMake.vue')
    },
    {
      path: '/content/product',
      name: 'content-product',
      meta: {
        authorization: {
          MANAGE_PRODUCT_HP: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/product/product-hp.vue')
    },
    {
      path: '/content/make-product-hp',
      name: 'content-make-product-hp',
      meta: {
        authorization: {
          MANAGE_PRODUCT_HP: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/product/product-hp-make.vue')
    },
    {
      path: '/content/edit-product-hp/:id_product_hp',
      name: 'content-edit-product-hp',
      meta: {
        authorization: {
          MANAGE_PRODUCT_HP: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/product/product-hp-edit.vue')
    },
    {
      path: '/content/meta-config',
      name: 'content-meta-config',
      meta: {
        authorization: {
          MANAGE_SEO_META: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/meta/MetaConfiguration.vue')
    },
    {
      path: '/content/meta-config/:id_meta',
      name: 'content-meta-config-id',
      meta: {
        authorization: {
          MANAGE_SEO_META: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/meta/MetaConfiguration.vue')
    },
    {
      path: '/content/meta',
      name: 'seo-meta-content',
      meta: {
        authorization: {
          MANAGE_SEO_META: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/meta/MetaList.vue')
    },
    {
      path: '/marketing/campaigns',
      name: 'marketing-campaigns',
      meta: {
        authorization: {
          MANAGE_MANUAL_CAMPAIGN: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/campaigns/List.vue')
    },
    {
      path: '/marketing/campaigns/:idCampaign',
      name: 'marketing-campaign',
      meta: {
        authorization: {
          MANAGE_MANUAL_CAMPAIGN: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/campaigns/addContacts.vue')
    },
    {
      path: '/marketing/alert-price',
      name: 'marketing-alert-price',
      meta: {
        authorization: {
          ALERT_PRICE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/alert-price.vue')
    },
    {
      path: '/content/carousel',
      name: 'content-carousel',
      meta: {
        authorization: {
          MANAGE_CAROUSEL: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/carousel/carouselList.vue')
    },
    {
      path: '/content/make-carousel',
      name: 'content-make-carousel',
      meta: {
        authorization: {
          MANAGE_CAROUSEL: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/carousel/makeCarousel.vue')
    },
    {
      path: '/content/make-carousel/:id_carousel',
      name: 'content-edit-carousel',
      meta: {
        authorization: {
          MANAGE_CAROUSEL: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/carousel/makeCarousel.vue')
    },
    {
      path: '/marketing/promotion-list',
      name: 'marketing-promotion-list',
      meta: {
        authorization: {
          MARKETING_PROMOTION_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/promotion-list.vue')
    },
    {
      path: '/content/manage-files',
      name: 'content-manage-files',
      meta: {
        authorization: {
          MANAGE_FILES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/files/manage-files.vue')
    },
    {
      path: '/marketing/email-config',
      name: 'marketing-email-config',
      meta :{
        authorization: {
          EMAIL_CONFIG: true
        }
      },

      component: () => import(/* webpackChunkName: "about" */ '../components/email-config/email-config.vue')
    },
    {
      path: '/marketing/sms-config',
      name: 'marketing-sms-config',
      meta :{
        authorization: {
          SMS_CONFIG: true
        }
      },

      component: () => import(/* webpackChunkName: "about" */ '../components/sms-config/sms-config.vue')
    },
    {
      path: '/marketing/sms-conversations',
      name: 'marketing-sms-conversations',
      meta :{
        authorization: {
          SMS_CONVERSATIONS: true
        }
      },

      component: () => import(/* webpackChunkName: "about" */ '../views/sms/sms-conversations.vue')
    },
    {
      path: '/marketing/alert-price-parts',
      name: 'marketing-alert-price-parts',
      meta :{
        authorization: {
          SMS_CONFIG: true
        }
      },

      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/alert-price-parts.vue')
    },
    {
      path: '/marketing/make-promotion',
      name: 'marketing-make-promotion',
      meta: {
        authorization: {
          MARKETING_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/make-promotion.vue')
    },
    {
      path: '/promotions/make-accessories-promotion',
      name: 'make-accessories-promotion',
      meta :{
        authorization: {
          ACCESSORIES_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/promotions/make-accessories-promotion.vue')
    },
    {
      path: '/marketing/make-promotion-2',
      name: 'marketing-make-promotion-2',
      meta: {
        authorization: {
          MARKETING_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/promotions/make-accessories-promotion.vue')
    },
    {
      path: '/promotions/edit-promotion/:id_custom_promotion',
      name: 'accessories-edit-promotion',
      meta: {
        authorization: {
          ACCESSORIES_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/promotions/make-accessories-promotion.vue')
    },
    {
      path: '/stripe/failure',
      name: 'stripe-failure',
      meta: {
        authorization: {
          MARKETING_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stripe/failure.vue')
    },
    {
      path: '/product/mapping/edit-feature-product/:id_product_mapping',
      name: 'product-edit-feature',
      meta: {
        authorization: {
          MARKETING_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/EditFeatureProduct')
    }, {
      path: '/product/order-product-list/edit-priority-product/:id_product_priority_rule',
      name: 'product-edit-order',
      meta: {
        authorization: {
          PRIORITY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../components/products/EditOrderProduct')
    }, {
      path: '/product/order-product-list-v2/edit-priority-product/:id_product_priority_rule',
      name: 'product-edit-order-v2',
      meta: {
        authorization: {
          PRIORITY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/AddNewPriorityRuleV2')
    },
    //page priority
    {
      path: '/stripe/success',
      name: 'stripe-success',
      meta: {
        authorization: {
          MARKETING_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/stripe/success.vue')
    },
    {
      path: '/sepa-request',
      name: 'sepa-request',
      meta: {
        authorization: {
          MANAGE_SEPA_REQUEST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/SepaBankRequestList')
    },
    {
      path: '/account-extract',
      name: 'account-extract',
      meta: {
        authorization: {
          BILLING_ACCOUNT_EXTRACT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/billing/AccountExtract.vue')
    },
    {
      path: '/sepa-bank-request-list',
      name: 'sepa-bank-request-list',
      meta: {
        authorization: {
          B2B_SEPA_BANK_REQUEST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/B2B/billing/SepaBankRequestList')
    },
    {
      path: '/product/price-file-upload',
      name: 'product-price-file-upload',
      meta: {
        authorization: {
          IMPORT_PRICE_DOCUMENT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/PriceFileUpload.vue')
    },
    {
      path: '/help',
      name: 'help',
      component: () => import(/* webpackChunkName: "about" */'../views/Help.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/help/orders',
      name: 'help-orders',
      component: () => import(/* webpackChunkName: "about" */'../components/help/orders.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/help/accounting',
      name: 'help-accounting',
      component: () => import(/* webpackChunkName: "about" */'../components/help/accounting.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/help/marketing',
      name: 'help-marketing',
      component: () => import(/* webpackChunkName: "about" */'../components/help/marketing.vue'),
      meta: {
        public: true
      }
    },

    {
      path: '/facebook-pages',
      name: 'FacebookPages',
      meta: {
        authorization: {
          MANAGE_FACEBOOK: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/facebook/List.vue')
    },
    {
      path: '/marketing/coupons',
      name: 'marketing-coupons',
      meta: {
        authorization: {
          MARKETING_COUPON_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/marketing/coupons/index.vue')
    },
    {
      path: '/tyres/pricing',
      name: 'tyres-pricing',
      meta: {
        authorization: {
          TYRE_CONFIG_PRICING: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/pricing.vue')
    },
    {
      path: '/tyres/products',
      name: 'tyres-products',
      meta: {
        authorization: {
          TYRE_PRODUCTS_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/products.vue')
    },
    {
      path: '/tyres/brands',
      name: 'tyres-brands',
      meta: {
        authorization: {
          TYRE_BRANDS_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/brands.vue')
    },
    {
      path: '/tyres/priorities',
      name: 'tyres-priorities',
      meta: {
        authorization: {
          TYRE_MANAGE_PRIORITIES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/priorities.vue')
    },
    {
      path: '/tyres/mounting',
      name: 'tyres-mounting',
      meta: {
        authorization: {
          TYRE_MANAGE_SERVICES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/mounting.vue')
    },
    {
      path: '/tyres/benchmark',
      name: 'tyres-benchmark',
      meta: {
        authorization: {
          TYRE_BENCHMARK: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/benchmark.vue')
    },
    {
      path: '/fitting-time-config',
      name: 'fitting-time-config',
      meta: {
        authorization: {
          FITTING_CONFIG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/FittingTimeConfig.vue')
    },
    {
      path: '/promotions',
      name: 'promotions',
      meta: {
        authorization: {
          PROMOTION_LIST: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/promotions/index.vue')
    },
    {
      path: '/promotions/make-tyre-promotion',
      name: 'make-tyres-promotions',
      meta: {
        authorization: {
          TYRE_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/promotions/make-promotion.vue')
    },
    {
      path: '/promotions/make-tyre-promotion/:id_custom_promotion',
      name: 'edit-tyres-promotions',
      meta: {
        authorization: {
          TYRE_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/promotions/make-promotion.vue')
    },
    {
      path: '/promotions/make-parts-promotion',
      name: 'make-parts-promotions',
      meta: {
        authorization: {
          PARTS_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/parts/promotions/make-parts-promotions.vue')
    },
    {
      path: '/promotions/make-parts-promotion/:id_custom_promotion',
      name: 'edit-parts-promotions',
      meta: {
        authorization: {
          PARTS_MAKE_PROMOTION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/parts/promotions/make-parts-promotions.vue')
    },
    {
      path: '/content/presentation',
      name: 'presentation-bloc',
      meta: {
        authorization: {
          MANAGE_CONTENT_PRESENTATION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/presentation/presentationList.vue')
    },
    {
      path: '/content/presentation/make-bloc',
      name: 'make-content-presentation',
      meta: {
        authorization: {
          MANAGE_CONTENT_PRESENTATION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/presentation/makePresentation.vue')
    },
    {
      path: '/content/carrier',
      name: 'list-carrier',
      meta: {
        authorization: {
          CONFIG_CARRIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/carrier/carrier-list.vue')
    },
    {
      path: '/content/carrier/set-shop-carrier/:id_carrier',
      name: 'edit-shop-carrier',
      meta: {
        authorization: {
          SET_CARRIER_SHOPS_ONLY: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/carrier/set-shops-carrier-only.vue')
    },
    {
      path: '/content/carrier/make-carrier',
      name: 'make-carrier',
      meta: {
        authorization: {
          CONFIG_CARRIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/carrier/make-carrier.vue')
    },
    {
      path: '/content/carrier/make-carrier/:id_carrier',
      name: 'edit-carrier',
      meta: {
        authorization: {
          CONFIG_CARRIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/carrier/make-carrier.vue')
    },
    {
      path: '/content/presentation/make-bloc/:id_presentation',
      name: 'edit-content-presentation',
      meta: {
        authorization: {
          MANAGE_CONTENT_PRESENTATION: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/presentation/makePresentation.vue')
    },
    {
      path: '/content/footer/make-footer',
      name: 'make-content-footer',
      meta: {
        authorization: {
          MANAGE_CONTENT_FOOTER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/footer/makeFooter.vue')
    },
    {
      path: '/content/footer/make-footer/:id_footer',
      name: 'edit-content-footer',
      meta: {
        authorization: {
          MANAGE_CONTENT_FOOTER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/footer/makeFooter.vue')
    },
    {
      path: '/content/footer',
      name: 'content-footer',
      meta: {
        authorization: {
          MANAGE_CONTENT_FOOTER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/footer/footer-list.vue')
    },
    {
      path: '/content/menu/make-menu',
      name: 'make-content-menu',
      meta: {
        authorization: {
          MANAGE_CONTENT_MENU: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/menu/makeMenu.vue')
    },
    {
      path: '/content/menu/make-menu/:id_menu',
      name: 'edit-content-menu',
      meta: {
        authorization: {
          MANAGE_CONTENT_MENU: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/menu/makeMenu.vue')
    },
    {
      path: '/content/menu',
      name: 'content-menu',
      meta: {
        authorization: {
          MANAGE_CONTENT_MENU: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/menu/menu-list.vue')
    },
    {
      path: '/content/image-product',
      name: 'content-image',
      meta: {
        authorization: {
          MANAGE_IMAGE_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/image/image-list.vue')
    },
    {
      path: '/content/family-infos',
      name: 'family-infos',
      meta: {
        authorization: {
          MANAGE_FAMILY_INFOS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/family-infos/family-infos-list.vue')
    },
    {
      path: '/content/family-infos/make-family-infos',
      name: 'make-content-family-infos',
      meta: {
        authorization: {
          MANAGE_FAMILY_INFOS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/family-infos/make-family-infos.vue')
    },
    {
      path: '/content/popular-vehicles',
      name: 'popular-vehicles',
      meta: {
        authorization: {
          MANAGE_POPULAR_VEHICLES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/popular-vehicles/popular-vehicles-list.vue')
    },
    {
      path: '/content/popular-vehicles/make-popular-vehicles',
      name: 'create-content-popular-vehicles',
      meta: {
        authorization: {
          MANAGE_POPULAR_VEHICLES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/popular-vehicles/make-popular-vehicles.vue')
    },
    {
      path: '/content/popular-vehicles/make-popular-vehicles/:id',
      name: 'edit-content-popular-vehicles',
      meta: {
        authorization: {
          MANAGE_POPULAR_VEHICLES: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/popular-vehicles/make-popular-vehicles.vue')
    },
    {
      path: '/content/family-infos/make-family-infos/:id',
      name: 'edit-content-family-infos',
      meta: {
        authorization: {
          MANAGE_FAMILY_INFOS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/family-infos/make-family-infos.vue')
    },
    {
      path: '/content/cms',
      name: 'cms-page',
      meta: {
        authorization: {
          MANAGE_CMS_PAGE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/cms/cmsList.vue')
    },
    {
      path: '/content/cms/make-page',
      name: 'make-content-cms',
      meta: {
        authorization: {
          MANAGE_CMS_PAGE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/cms/makeCms.vue')
    },
    {
      path: '/content/cms/make-page/:id_cms',
      name: 'edit-content-cms',
      meta: {
        authorization: {
          MANAGE_CMS_PAGE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/cms/makeCms.vue')
    },
    {
      path: '/validate-order-modification/:data_source/:id_order',
      name: 'validate-order-modification',
      meta: {
        authorization: {
          MANAGE_CMS_PAGE: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/order/ValidateOrderModification.vue')
    },
    {
      path: '/content/blog',
      name: 'blog-page',
      meta: {
        authorization: {
          MANAGE_BLOG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/blog/blogList.vue')
    },
    {
      path: '/content/blog/make-page',
      name: 'make-content-blog',
      meta: {
        authorization: {
          MANAGE_BLOG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/blog/makeBlog.vue')
    },
    {
      path: '/content/blog/make-page/:id_blog',
      name: 'edit-content-blog',
      meta: {
        authorization: {
          MANAGE_BLOG: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/content/blog/makeBlog.vue')
    },
    {
      path: '/product/make-promo-product/:id/:link_rewrite',
      name: 'make-promo-product',
      meta: {
        authorization: {
          ACCESSORY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/promotions/make-promo-by-product.vue')
    },
    {
      path: '/product/promo-product/:id/:link_rewrite',
      name: 'promo-product',
      meta: {
        authorization: {
          ACCESSORY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/PromoProduct.vue')
    },
    {
      path: '/product/accessory-product-list',
      name: 'accessory-product-list',
      meta: {
        authorization: {
          ACCESSORY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/ListAccessoryProducts.vue')
    },
    {
      path: '/product/product-specific-content',
      name: 'product-specific-content',
      meta: {
        authorization: {
          MANAGE_SUPPLIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/ProductSpecificContent.vue')
    },
    {
      path: '/product/make-product-specific-content',
      name: 'make-product-specific-content',
      meta: {
        authorization: {
          MANAGE_SUPPLIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/MakeProductSpecificContent.vue')
    },
    {
      path: '/product/make-product-specific-content/:id',
      name: 'edit-product-specific-content',
      meta: {
        authorization: {
          MANAGE_SUPPLIER: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/MakeProductSpecificContent.vue')
    },
    {
      path: '/category/product-category-model-mapping',
      name: 'product-category-model-mapping',
      meta: {
        authorization: {
          MAPPING_PRODUCT_CATEGORY_MODEL: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/categories/mapping-categories.vue')
    },
    {
      path: '/category/supplier-category-model-mapping',
      name: 'supplier-category-model-mapping',
      meta: {
        authorization: {
          MAPPING_SUPPLIER_CATEGORY_MODEL: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/supplier/MappingModelCategory.vue')
    },
    {
      path: '/product/make-accessory-product',
      name: 'make-accessory-product',
      meta: {
        authorization: {
          ACCESSORY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/MakeAccessoryProduct.vue')
    },
    {
      path: '/product/make-accessory-product/:id_product',
      name: 'make-accessory-product_id',
      meta: {
        authorization: {
          ACCESSORY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/MakeAccessoryProduct.vue')
    },
    {
      path: '/product/status-accessory-product/:id_product',
      name: 'status-accessory-product_id',
      meta: {
        authorization: {
          ACCESSORY_PRODUCT: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/product/AccessoryProductStatus.vue')
    },
    {
      path: '/tyres/priority-hp',
      name: 'tyres-priority-hp',
      meta: {
        authorization: {
          TYRE_MANAGE_PRIORITIES_HP: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/tyres/HpPriority.vue')
    },
    {
      path: '/user/account',
      name: 'account',
      component: () => import(/* webpackChunkName: "about" */ '../views/user/Account.vue')
    },
    {
      path: '/breakdown/buy-product',
      name: 'buy-product',
      meta: {
        authorization: {
          BUY_PARTS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/breakdown/BuyProduct.vue')
    },
    {
      path: '/breakdown/follow-orders',
      name: 'follow-orders',
      meta: {
        authorization: {
          BUY_PARTS: true
        }
      },
      component: () => import(/* webpackChunkName: "about" */ '../views/breakdown/FollowOrders.vue')
    },
  ]
})

export default router
