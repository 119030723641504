import Vue from 'vue';
import VueI18n from 'vue-i18n';

import fr from '../locales/fr.js';
import en from '../locales/en.js';

Vue.use(VueI18n);

const supportedLanguages = ['en', 'fr'];
const defaultLanguage = 'fr';
const browserLanguage = navigator.language.split('-')[0];
const savedLanguage = localStorage.getItem('lang') || (supportedLanguages.includes(browserLanguage) ? browserLanguage : defaultLanguage);
if(!localStorage.getItem('lang')) {
  localStorage.setItem('lang', savedLanguage);
}

const i18n = new VueI18n({
  locale: savedLanguage,
  fallbackLocale: 'en',
  messages: {
    en,
    fr
  },
});

export default i18n;