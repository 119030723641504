import en from 'vuetify/lib/locale/en';

export default {
  $vuetify: {
    ...en,
  },
  catalog_access: "catalog access",
  home: "home",
  mandates: "mandates",
  reports: "reports",
  sales: "sales",
  campaign_marketing: "campaign marketing",
  automated_email_sms: "automated email & sms",
  shops: "shops",
  duplicate: "duplicate",
  create_shop_group: "create_shop_group",
  manage_concessions: "manage_concessions",
  list: "list",
  manage_payments: "manage_payments",
  users: "users",
  phone_order: "phone_order",
  orders: "orders",
  carts: "carts",
  manufacturer_club: "manufacturer club",
  accounting: "accounting",
  billing_accounts: "billing accounts and mandates",
  sepa_samples: "sepa_samples",
  invoices_memos: "invoices & memos",
  subscriptions: "subscriptions",
  billing_conditions: "billing conditions",
  products: "products",
  tax: "tax",
  statistics: "statistics",
  commissions_deliveries: "commissions & deliveries",
  sepa_mandate: "sepa mandate",
  marketing: "marketing",
  automated_templates: "automated templates",
  price_alerts: "price alerts",
  duplicate_advanced_promotions: "duplicate advanced promotions",
  advanced_promotions_coupons: "advanced promotions coupons",
  simple_promotions: "simple promotions",
  coupons: "coupons",
  dead_stock_promotion_config: "dead stock promotion config",
  parts_price_alerts: "parts price alerts",
  crm_email_sms: "crm email & sms",
  email_configuration: "email configuration",
  sms_configuration: "sms configuration",
  conversational_sms: "conversational sms",
  pending_campaigns: "pending campaigns",
  manual_campaign_management: "manual campaign management",
  manual_campaign_contacts: "manual campaign contacts",
  gdpr_unsubscription: "gdpr unsubscription",
  stock_info: "stock info",
  interface: "interface",
  vn_vo_test: "vn/vo test",
  stop_mails_sms_test: "stop mails/sms test",
  mecaplanning: "mecaplanning",
  stock_test: "stock test",
  stock_mapping: "stock mapping",
  vn_vo_configuration: "vn/vo configuration",
  stop_mails_sms_configuration: "stop mails/sms configuration",
  stock_configuration: "stock configuration",
  stock_qualification: "stock qualification",
  stock_qualification_report: "stock qualification report",
  mecaplanning_configuration: "mecaplanning configuration",
  interface_status: "interface status",
  interface_status_per_shop: "interface status per shop",
  site_content: "site content",
  menu: "menu",
  carousel: "carousel",
  homepage_content: "homepage content",
  homepage_products: "homepage products",
  banner_model: "banner model",
  footer: "footer",
  meta_configuration: "meta configuration",
  vehicle_display: "vehicle display",
  cms_page: "cms page",
  blog: "blog",
  family_page_content: "family page content",
  file_management: "file management",
  carrier: "carrier",
  product_catalog: "product catalog",
  accessory_products: "accessory products",
  category_management: "category management",
  supplier_management: "supplier management",
  supplier_model_mapping: "supplier model_mapping",
  category_mapping: "category_mapping",
  category_assignment_rules: "category assignment_rules",
  product_mapping: "product mapping",
  scrapped_products: "scrapped products",
  product_order: "product order",
  unknown_products: "unknown products",
  specific_product_content: "specific product content",
  tires: "tires",
  prices: "prices",
  priorities: "priorities",
  homepage_priority: "homepage priority",
  mounting: "mounting",
  brands: "brands",
  benchmark: "benchmark",
  generic_products: "generic products",
  search_by_reference: "search by reference",
  my_shops: "my shops",
  purchase_settings: "purchase settings",
  suppliers: "suppliers",
  customer_type: "customer type",
  selling_price: "selling price",
  breakdown: "breakdown",
  purchase_breakdown: "purchase breakdown",
  order_tracking: "order tracking",
  onboarding_form: "onboarding form",
  fitting_time: "fitting time",
  price_file_upload: "price file upload",
  sales_report: "sales report",
  group: "group",
  groups: "groups",
  select_all: "select all",
  others: "others",
  all: "all",
  until: "until",
  turnover_includes_vat: "turnover includes VAT",
  which_is_dead_stock: 'which is dead stock',
  number_of_orders: 'number of orders',
  last_months: "last months",
  by_group: "by group",
  by_make: "by make",
  by_shop: "by shop",
  global: "global",
  shop: "shop",
  order_date: "order date",
  client: "client",
  reference: "reference",
  quantity: "quantity",
  amount: "amount",
  includes_vat: "includes vat",
  incl_amount: "tax included amount",
  excl_amount: "tax excluded amount",
  promotion_type: "promotion type",
  marketing_report: "marketing report",
  shops_list: "list of shops",
  delete: "delete",
  apply: "apply",
  marketing_report_welcome: "welcome to your marketing report",
  marketing_report_infos: "here you will find all the information regarding the communications automatically sent to your customers",
  marketing_report_tpl1: "after ordering a vehicle (tpl 1)",
  marketing_report_tpl2: "after ordering a vehicle with a discount coupon (tpl 2)",
  marketing_report_tpl3: "after the vehicle is received by the customer (tpl 3)",
  marketing_report_tpl4: "before a workshop appointment (tpl 4)",
  marketing_report_nb: "nb: full weeks, from monday 00:01 to sunday 23:59 inclusive, metropolitan france time",
  view_templates: "view templates",
  no_data_to_display: "no data to display",
  close: "close",
  folder: "folder",
  folders: "folders",
  imported_folders: "imported folders",
  folders_vn_vo: "number of vn & vo folders received",
  email: "email",
  sms: "sms",
  deliverability_of_automatic_communications: "deliverability of automatic communications",
  marketing_report_desc1: "the deliverability of a communication is the fact that this communication reaches the recipient's inbox, which guarantees the success of your campaigns.",
  marketing_report_desc2: "to calculate the deliverability rate of your campaigns, simply apply the following formula",
  marketing_report_desc3: "(number of delivered communications / total number of sends) x 100.",
  marketing_report_desc4: "deliverability depends on several factors",
  marketing_report_desc5: "content quality: sending unwanted or irrelevant content to your audience presents the risk of being marked as spam, thus damaging your sender reputation. avoid this by sending quality and relevant content that will not be considered spam. by providing useful content to your audience, your unsubscribe rate will remain low, which is another important factor!",
  marketing_report_desc6: "the quality of your contact list: if some addresses do not exist or are incorrect",
  marketing_report_desc7: "recipient reaction: is your open rate good or, on the contrary, do your users tend to unsubscribe or report your emails as spam, or not click or open your emails?",
  marketing_report_desc8: "email deliverability is crucial for the success of your email campaigns.",
  marketing_report_desc9: "how can you generate interest from your customers and prospects and drive a purchase if they never receive your marketing emails?",
  marketing_report_desc10: "beyond deliverability, it is essential to generate engagement from your customers/prospects by creating interesting and relevant offers that your recipients want to receive.",
  marketing_report_desc11: "over time, your contact lists evolve. some recipients no longer want to receive marketing emails, others have deleted or no longer use their email address.",
  marketing_report_desc12: "to ensure the good deliverability of your emails, it is therefore essential to regularly update your databases.",
  marketing_report_desc13: "manage hard bounces by sorting inactive addresses or non-existent emails.",
  marketing_report_desc14: "remove recipients who have not opened your emails or clicked on a link in the last two years.",
  marketing_report_desc15: "number of automated email sends",
  marketing_report_desc16: "number of automated emails tpl 1 / tpl 2 / tpl 3 or tpl4 sent to your customers",
  marketing_report_desc17: "number of automated sms sends",
  marketing_report_desc18: "number of automated sms tpl 1 / tpl 2 / tpl 3 or tpl4 sent to your customers",
  marketing_report_desc19: "this calculation gives you a percentage corresponding to the open rate of each communication",
  marketing_report_desc20: "(number of email openings / number of delivered emails) * 100",
  marketing_report_desc21: "let's take an example: we sent a newsletter to 4,600 contacts and 1,600 opened it, so the open rate will be 34.78%",
  marketing_report_desc22: "analyzing the open rate allows you to evaluate the relevance of your offer or topic presented, as well as the engagement generated among these contacted customers.",
  marketing_report_desc23: "the click rate is a performance indicator that corresponds to the proportion of recipients who clicked on at least one link in the email. to calculate it",
  marketing_report_desc24: "click rate = (number of clicks on a link in an email / number of delivered emails) x 100",
  marketing_report_desc25: "let's imagine we delivered an email to 4,600 contacts. among them, 300 click on one of the links in the email. in this case, the click rate of this campaign is 6.52%",
  marketing_report_desc25_2: "the click rate is one of the key performance indicators to monitor to evaluate the effectiveness of an email campaign, the open rate of an email campaign shows how many recipients think the email is worth their attention. the click rate goes further as it reflects (positively or negatively) the relevance and quality of the message and its propensity to encourage recipients to take action.",
  marketing_report_desc26: "a high click rate means that the content of the email resonates with the issues of your target customers.",
  marketing_report_desc27: "email open rate",
  marketing_report_desc28: "email click rate",
  all_data: "all data",
  this_month: "this month (m0)",
  last_month: "last month (m-1)",
  last_three_month: "last three months (m0 + m-1 + m-2)",
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
  marketing_campaign_report: "marketing campaign report",
  name: "Name",
  sin_status: "SIB Status",
  sender: "Sender",
  send_date: "Send Date",
  contacts: "Contacts",
  approved: "Approved",
  opened: "Opened",
  open: 'Open',
  view: 'View',
  click: "Click",
  unsubscribes: "Unsubscribes",
  rate: "Rate",
  search: "Search",
  months: "Months",
  filter: "Filter",
  filters: "Filters",
  billing_account: "Billing Account",
  delivery: "Delivery",
  invoices: "Invoices",
  List_of_articles: "List of Articles",
  payment: "Payment",
  payments: "Payments",
  search_by_reference_order_id_customer_name_or_first_name: "Search by Reference, Order ID, Customer Name or First Name",
  all_status: "All Status",
  unprocessed_oders: "Unprocessed Orders",
  in_preparation: "In Preparation",
  ready_to_ship: "Ready to Ship",
  shipped: "Shipped",
  delivered: "Delivered",
  available_for_pickup_in_dealership: "Available for Pickup in Dealership",
  Cancelled: "Cancelled",
  refunded: "Refunded",
  awaiting_restocking: "Awaiting Restocking",
  paid: "Paid",
  all_prices: "All Prices",
  price_over_250: "Price Over 250€",
  price_over_500: "Price Over 500€",
  all_makes: "All Makes",
  total: "Total",
  state: "Status",
  date: "Date",
  action: "Action",
  transfers: "Transfers",
  description: "Description",
  stripe_reference: "Stripe Reference",
  payment_by_order: "Payment by Order",
  refund_by_order: "Refund by Order",
  execution_date: "Execution Date",
  invoice_number: "Invoice Number",
  total_customer_order: "Total Customer Order",
  total_in_bank: "Total in Bank",
  transfer_id: "Transfer ID",
  company_name: "Company Name",
  iban: "IBAN",
  stripe_mangopay_account: "Stripe/Mangopay Account",
  commission: "Commission",
  payment_sepa: "SEPA Payment",
  payment_commission_restraint: "Payment Commission Restraint",
  purchase: "Purchase",
  one_bill_at_the_end_of_the_month: "One Bill at the End of the Month",
  invoice_per_order: "Invoice per Order",
  do_not_charge: "Do Not Charge",
  invoices_memos_list: "Invoices/Memos List",
  invoice_blocking_date: "Invoice Blocking Date",
  account_number: "Account Number",
  number: "Number",
  due_date: "Due Date",
  billed_to: "Billed to",
  order: "Order",
  purchase_order_number: "Purchase Order Number",
  product: "Product",
  search_by_number: "Search by Number",
  min_date: "Min Date",
  max_date: "Max Date",
  document: "Document",
  documents: "Documents",
  commission_per_order: "Commission per Order",
  refund_of_commissions: "Refund of Commissions",
  creation_date: "Creation Date",
  make: "Make",
  alert_price_desc1: "Here is the list of items for which we have received the most customer requests, ranked by priority.",
  alert_price_desc2: "By applying a promotion, a message will be automatically sent to them, increasing sales opportunities.",
  promotion_currently_applied: "Promotion Currently Applied",
  promote: "Promote",
  add_promotion: "Add Promotion",
  shops_concerned: "Concerned Shops",
  create_the_promotion: "Create the Promotion",
  start_date: "Start Date",
  end_date: "End Date",
  amount_of_the_reduction: "Amount of the Reduction",
  product_name: "Product Name",
  unit_value: "Unit Value",
  number_of_requests: "Number of Requests",
  total_requests: "Total Requests",
  products_from_partner_dealerships: "Products from Partner Dealerships",
  search_for_your_reference_in_troubleshooting: "Search for Your Reference in Troubleshooting",
  products_from_partner_dealerships_desc1: "Your price and stock for this reference were checked less than 24 hours ago.",
  products_from_partner_dealerships_desc2: "This means that the stock and price for your reference have not been checked for more than a day.",
  24_72: "48/72h",
  order_now: "Order Now",
  contact_us: "Contact Us",
  product_added_successfully: "Product Added Successfully",
  problem_adding_product_to_cart: "Problem Adding Product to Cart",
  must_enter_your_first_and_last_name: "You Must Enter Your First and Last Name",
  click_here: "Click Here",
  stock: "Stock",
  seller_dpt: "Seller DPT",
  fiability: "Reliability",
  price_includes_vat: "Price Includes VAT",
  delivery_time: "Delivery Time",
  accessory: "Accessory",
  part: "Part",
  accessories: "Accessories",
  parts: "Parts",
  colissimo_tracking: "Colissimo Tracking",
  see_details: "See Details",
  cancel: "Cancel",
  purchaser: "Purchaser",
  total_price: "Total Price",
  promotion: "Promotion",
  promotions: "Promotions",
  discount: "Discount",
  from: "From",
  to: "To",
  promotion_add: "Add Promotion",
  type: "Type",
  promotion_for_tyres: "Promotion for Tyres",
  promotion_for_parts: "Promotion for Parts",
  promotion_for_accessories: "Promotion for Accessories",
  choose: "Choose",
  concessions: "Concessions",
  families: "Families",
  offer: "Offer",
  dates: "Dates",
  saving: "Saving",
  next: "Next",
  your_query_runs_correctly_and_finds: "Your query runs correctly and finds",
  products_by_shop: "Products by Shop",
  example: "Example",
  loading: "Loading",
  test_query: "Test Query",
  text_tag: "Text Tag",
  or_else: "Or Else",
  coupon_url: "Coupon URL",
  load_pdf_coupon: "Load PDF Coupon",
  image_tag: "Image Tag",
  load_image: "Load Image",
  info_part: "Part Info",
  successfully_saved: "Successfully Saved",
  return_to_list: "Return to List",
  Level_error: "Level Error",
  you_have_a_missing_condition: "You have a missing condition",
  you_have_a_missing_operator: "You have a missing operator",
  you_have_a_Missing_Value_for_a_condition: "You have a missing value for a condition",
  generic_element: "Generic Element",
  promo_code: "Promo Code",
  family: "Family",
  sub_family: "Sub Family",
  sub_sub_family: "Sub-Sub Family",
  with_promotion: "With Promotion",
  contains: "Contains",
  does_not_contain: "Does Not Contain",
  starts_with: "Starts With",
  ends_with: "Ends With",
  Is: "Is",
  equal_to: "Equal To",
  add_a_group: "Add a Group",
  attribute: "Attribute",
  condition: "Condition",
  add_a_condition: "Add a Condition",
  a_group_of_conditions_must_have_at_least_two_children: "A group of conditions must have at least two children",
  error_you_must_add_at_least_one_condition_or_condition_group: "Error: You must add at least one condition or condition group",
  the_name_field_is_required: "The name field is required",
  you_must_choose_at_least_one_concession: "You must choose at least one concession",
  you_must_test_your_request: "You must test your request!",
  your_request_test_must_pass: "Your request test must pass!",
  the_request_must_return_at_least_one_product: "The request must return at least one product!",
  you_must_specify_the_promotion_amount: "You must specify the promotion amount!",
  you_must_specify_the_start_date: "You must specify the start date!",
  you_must_specify_the_end_date: "You must specify the end date!",
  unexpected_error_contact: "Unexpected error. Contact",
  operator: "Operator",
  category: "Category",
  model: "Model",
  less_than: "Less Than",
  more_than: "More Than",
  among_the_list: "Among the List",
  you_cannot_modify_the_request_for_an_already_created_promotion: "You cannot modify the request for an already created promotion",
  price: "Price",
  limit_promotions_to_products_in_stock: "Limit Promotions to Products in Stock",
  mounting_discount: "Mounting Discount",
  info_tyre: "Tyre Info",
  free_mounting: "Free Mounting",
  attention_due_to_this_change_carousels_related_to_this_promotion_will_only_display_for_shops_configured_here: "Attention! Due to this change, carousels related to this promotion will only display for shops configured here!",
  you_must_specify_the_discount_value: "You must specify the discount value!",
  you_must_specify_the_mounting_discount_value: "You must specify the mounting discount value!",
  you_must_fill_in_the_tag_text_field: "You must fill in the text tag field",
  you_must_provide_an_image: "You must provide an image",
  you_must_provide_a_coupon: "You must provide a coupon",
  you_must_provide_a_valid_url: "You must provide a valid URL",
  an_error_occurred_while_loading_the_pdf_please_try_again: "An error occurred while loading the PDF. Please try again.",
  an_error_occurred_while_loading_the_image_please_try_again: "An error occurred while loading the image. Please try again.",
  the_dimension_you_are_looking_for: "The dimension you are looking for",
  greater_than_or_equal_to: "Greater Than or Equal To",
  less_than_or_equal_to: "Less Than or Equal To",
  summer: "Summer",
  winter: "Winter",
  four_seasons: "Four Seasons",
  price_excluding_tax: "Price Excluding Tax",
  product_reference: "Product Reference",
  ean_reference: "EAN Reference",
  manufacturer: "Manufacturer",
  diameter: "Diameter",
  season: "Season",
  approval: "Approval",
  dimensions: "Dimensions",
  non_uniform_setting: "Non-uniform setting",
  save: "Save",
  premium: "Premium",
  quality_price: "Quality Price",
  budget: "Budget",
  all_shops: "All shops",
  mounting_services: "Mounting services",
  different_sources: "Different sources",
  enter_prices_in_spid_cockpit: "Enter prices in SPID Cockpit",
  use_services_configured_in_quotation_tool_wyz: "Use services configured in the WYZ quotation tool",
  some_shops_have_this_service_active: "Some shops have this service active",
  some_shops_have_higher_amount: "Some shops have a higher amount",
  shop_information: "Shop Information",
  not_defined_yet: "Not defined yet",
  billing_mode: "Billing mode",
  stripe_account: "Stripe Account",
  link_to_stripe: "Link to Stripe",
  assign_group: "Assign Group",
  define_billing_account: "Define Billing Account",
  inactive: "Inactive",
  active: "Active",
  status: "Status",
  domain_ssl: "Domain SSL",
  billing_acc: "Billing Account",
  pause_my_ecommerce_sales: "Pause my e-commerce sales",
  activate_now: "Activate now",
  last_three_month_2: "Last 3 months",
  last_six_month: "Last 6 months",
  all_history: "All history",
  order_warn_1: "This order has not yet been transferred to your bank account. It will be transferred when you indicate that the part is available for collection at the dealership.",
  order_warn_2: "This order has been cancelled. The customer has been refunded.",
  order_warn_3: "No parcel Label for this order",
  order_warn_4: "This order has been partially refunded. We have proceeded to reimburse the customer.",
  order_warn_5: "A return request has been made for this order..",
  order_warn_6: "une demande d'échange a été effectuée pour cette commande.",
  order_warn_7: "An exchange request has been made for this order.",
  order_warn_8: "Exchange request pending for this order.",
  order_warn_9: "validation de demande de Retour en attente pour cette commande.",
  order_warn_10: "Validation of pending return request for this order",
  order_warn_11: "This order has not yet been transferred to your bank account. It will be when it is scanned by La Poste.",
  order_warn_12: "This order has not yet been transferred to your bank account. It will be when you invoice the end customer.",
  order_warn_13: "Notify us via Chat as soon as you receive the parcel so that we can refund the customer or exchange the product.",
  order_warn_14: "",
  cancel_request: "Cancel request",
  do_you_confirm: "Do you want to confirm?",
  confirm: "Confirm",
  old_request_will_cancel: "Old requests will be cancelled",
  colissimo_return_label_was_generated: "A return parcel label has been generated for this order.",
  colissimo_return_label_was_sent: "A return request has been sent for this order",
  parcel_well_received: "Packages received in good condition",
  by: 'by',
  for: 'for',
  pickup_address: "Pickup addresse",
  delivery_address: "Delivery addresse",
  cart_shop: "Cart shop",
  purchase_shop: "Purchase Shop",
  delivery_method: "Delivery method",
  collection_site: "Collection site",
  of_which_x_paid_in_addition: "of which {x} {device} paid in addition",
  refunded_x: "{x} {device} was refunded",
  delivery_fee: "Delivery fee",
  serenity_option: "Serenity option",
  tracking_url: "Tracking url",
  tracking: "Tracking",
  date_of_mounting_appointment:"Date of mounting appointment",
  print: 'Print',
  add_a_note: "Add a note",
  write_a_note: "Write a note ?",
  catalog_price: "Catalogue price",
  purchase_price: "Purchase price",
  expected_delivery_date : "Expected delivery date",
  article: "Article",
  assignment: "Assignment",
  return_exchange: "RETURN/EXCHANGE",
  product_modification: "Product modification",
  manage_parcel_label: "Manage Parcel label",
  upload_parcel_label: "Upload Parcel label",
  upload_parcel_label_2: "Uploade Parcel label",
  manage_parcel_labels: "Manage Parcel labels",
  a_problem:        "A problem ?",
  report_a_problem: "report a problem",
  messaging: 'Messaging',
  new_message: "New Message",
  last_message: "Last message",
  link: "link",
  no_history:"No history",
  are_products_available: "Are the products available?",
  availability: "Availability",
  expedition: 'Expedition',
  billing: 'Billing',
  yes: 'Yes',
  no: 'No',
  no_i_have_to_order_some: "No I have to order some products",
  wyz_order_code: "WYZ Order code",
  product_availability_date: "The product availability date ?",
  edit: 'Edit',
  save_dates: "Save dates",
  order_stepper_message_1:"The customer wants to pick up the items at the dealership",
  order_stepper_message_2:"Please validate this step when the order is available at the counter. The customer will receive a message automatically and you will be paid.",
  order_stepper_message_3: "The package is {order_state} and you can now invoice the customer.",
  customer_pickup_date: 'Customer pickup date',
  optional: 'optional',
  excellent: "Excellent !",
  invoicing_address: 'Billing Address',
  intern_invoice_number: "Intern invoice number",
  billing_details: 'Billing details',
  mounting_fee: "Mounting fee",
  vat_excl: "Vat Excl",
  vat_incl: "Vat incl",
  bill_to: 'Bill to {email}',
  you_can_see_regulations_deatil_below: "You can see details of the regulations below.",
  additional_information: 'Additional information',
  for_this_order_commission_is: 'For this is order commission will be',
  SPID_commission: 'SPID Commission'
};