<template>
  <v-app style="min-width: 700px" :dark="$auth.ensure('DARK_MODE')">
    <template>
      <div v-if="isUserConnected" class="connected-components">
        <stripe-alert />
        <mandate-alert/>
        <tyre-alert />
        <CompleteProfileAlert />
        <v-navigation-drawer app
          v-model="drawer" 
          large 
          width="320"
          fixed
          clipped
          class="main-nav-drawer">
          <v-list>
            <v-list-item link @click="$router.push('/')" v-if="$auth.ensure('SPID_HOME')">
              <v-list-item-icon>
                <v-icon>home</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t('home') | capitalize() }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
                prepend-icon="mdi-notebook-edit-outline"
                v-model="menu.reports"
                v-if="
                $auth.ensure('MONTHLY_REPORT') ||
                $auth.ensure('MONTHLY_REPORTS') ||
                $auth.ensure('MARKETING_REPORT') ||
                $auth.ensure('TRAFFIC_REPORT')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('reports') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item
                  @click="$router.push('/reports/sales')"
                  v-if="$auth.ensure('MONTHLY_REPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('sales') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/reports/campaign-marketing')"
                  v-if="$auth.ensure('MARKETING_REPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('campaign_marketing') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/reports/marketing')"
                  v-if="$auth.ensure('MARKETING_REPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('automated_email_sms') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/reports/traffic')"
                  v-if="$auth.ensure('TRAFFIC_REPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>Trafic</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
                prepend-icon="mdi-store"
                v-model="menu.shops"
                v-if="
                $auth.ensure('LIST_SHOPS') ||
                $auth.ensure('ADD_SHOP') ||
                $auth.ensure('EDIT_CGV') ||
                $auth.ensure('SHOPS_CONFIG') ||
                $auth.ensure('MANAGE_SHOP_GROUPED') ||
                $auth.ensure('MANAGE_GROUPS')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('shops') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item @click="$router.push('/shops/create')"
                           v-if="$auth.ensure('ADD_SHOP')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('duplicate') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/shops/grouped-list')"
                  v-if="$auth.ensure('MANAGE_SHOP_GROUPED')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('create_shop_group') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/shops/groupes')"
                  v-if="$auth.ensure('MANAGE_GROUPS')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('manage_concessions') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/shops')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('list') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/shops/config')"
                  v-if="$auth.ensure('SHOPS_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('manage_payments') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-item
                @click="$router.push('/users')"
                v-if="$auth.ensure('LIST_USERS')"
            >
              <v-list-item-action>
                <v-icon>people</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('users') | capitalize() }}</v-list-item-title>
            </v-list-item>

            <v-list-item
                @click="$router.push('/fast-order')"
                v-if="$auth.ensure('FAST_ORDER')"
            >
              <v-list-item-action>
                <v-icon>phone</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('phone_order') | capitalize() }}</v-list-item-title>
            </v-list-item>

            <v-list-item
                @click="$router.push('/orders')"
                v-if="$auth.ensure('B2B_ORDER_LIST')"
            >
              <v-list-item-action>
                <v-icon>euro_symbol</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('orders') | capitalize() }}</v-list-item-title>
            </v-list-item>

            <v-list-item
                @click="$router.push('/commande')"
                v-if="$auth.ensure('ORDER_LIST')"
            >
              <v-list-item-action>
                <v-icon>euro_symbol</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('orders') | capitalize() }}</v-list-item-title>
            </v-list-item>

            <v-list-item
                @click="$router.push('/carts')"
                v-if="$auth.ensure('CART_LIST')"
            >
              <v-list-item-action>
                <v-icon>shopping_cart</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('carts') | capitalize() }}</v-list-item-title>
            </v-list-item>

            <v-list-group
                prepend-icon="directions_car"
                v-model="menu.nissan"
                v-if="$auth.ensure('NISSAN_DEALER_LIST') || $auth.ensure('KIA_DEALER_LIST') || $auth.ensure('SUZUKI_DEALER_LIST')"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('manufacturer_club') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item
                  @click="$router.push('/nissan-dealer-list')"
                  v-if="$auth.ensure('NISSAN_DEALER_LIST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>Nissan</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/kia-dealer-list')"
                  v-if="$auth.ensure('KIA_DEALER_LIST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>KIA</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/suzuki-dealer-list')"
                  v-if="$auth.ensure('SUZUKI_DEALER_LIST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>SUZUKI</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
                prepend-icon="request_page"
                v-model="menu.billing"
                v-if="
                $auth.ensure('MANAGE_BILLING_ACCOUNTS') ||
                $auth.ensure('UNPAID_STRIPE_INVOICES') ||
                $auth.ensure('SEPA_CONFIG') ||
                $auth.ensure('MANAGE_SEPA_REQUEST') ||
                $auth.ensure('INVOICE_FILES') ||
                $auth.ensure('BILLING_ACCOUNT_EXTRACT') ||
                $auth.ensure('B2B_PAYOUT_LIST')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('accounting') | capitalize() }}</v-list-item-title>
              </template>
              <v-list-item
                  @click="$router.push('/payouts')"
                  v-if="$auth.ensure('B2B_PAYOUT_LIST')"
              >
                <v-list-item-action>
                  <!-- <v-icon>money</v-icon> -->
                </v-list-item-action>
                <v-list-item-title>{{ $t('payments') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/billing-account')"
                  v-if="$auth.ensure('MANAGE_BILLING_ACCOUNTS')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('billing_accounts') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/sepa-request')"
                  v-if="$auth.ensure('MANAGE_SEPA_REQUEST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('sepa_samples') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/invoice-files')"
                  v-if="$auth.ensure('INVOICE_FILES')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('invoices_memos') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/subscriptions')"
                  v-if="$auth.ensure('MANAGE_BILLING_SUBSCRIPTION')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('subscriptions') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/companies')"
                  v-if="$auth.ensure('MANAGE_COMPANY')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('companies') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/billing-conditions')"
                  v-if="$auth.ensure('MANAGE_BILLING_CONDITION')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('billing_conditions') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/billing-products')"
                  v-if="$auth.ensure('MANAGE_BILLING_PRODUCT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('products') | capitalize() }}</v-list-item-title>
              </v-list-item>
          
              <v-list-item
                  @click="$router.push('/billing-tax')"
                  v-if="$auth.ensure('MANAGE_BILLING_TAX')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('tax') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/billing-static')"
                  v-if="$auth.ensure('BILLING_STATISTICS')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('statistics') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/commissions-summary')"
                  v-if="$auth.ensure('B2B_COMMISSIONS_SUMMARY')"
              >
                <v-list-item-action> </v-list-item-action>
                <v-list-item-title>{{ $t('commissions_deliveries') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/mandat')"
                  v-if="$auth.ensure('SEPA_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('sepa_mandate') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
                prepend-icon="analytics"
                v-model="menu.marketing"
                v-if="
                $auth.ensure('ALERT_PRICE') ||
                $auth.ensure('MARKETING_COUPON_LIST') ||
                $auth.ensure('SHOPS_ADVANCED_DISCOUNT') ||
                $auth.ensure('PARTS_MAKE_PROMOTION') ||
                $auth.ensure('TYRE_MAKE_PROMOTION')|| 
                $auth.ensure('MARKETING_REPORT')||
                $auth.ensure('PROMOTION_LIST') ||
                $auth.ensure('ACCESSORIES_MAKE_PROMOTION')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('marketing') | capitalize() }}</v-list-item-title>
              </template>
              <v-list-item
                @click="$router.push('/marketing/templates-list')"
                v-if="$auth.ensure('MARKETING_REPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('automated_templates') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/marketing/alert-price')"
                  v-if="$auth.ensure('ALERT_PRICE')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('price_alerts') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/shops/advanced-discount')"
                  v-if="$auth.ensure('SHOPS_ADVANCED_DISCOUNT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('duplicate_advanced_promotions') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/marketing/advanced-discount-coupon')"
                  v-if="$auth.ensure('MARKETING_ADVANCED_DISCOUNT_COUPON')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('advanced_promotions_coupons') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/promotions')"
                  v-if="
                  $auth.ensure('PROMOTION_LIST') ||
                  $auth.ensure('TYRE_MAKE_PROMOTION') ||
                  $auth.ensure('PARTS_MAKE_PROMOTION') ||
                  $auth.ensure('ACCESSORIES_MAKE_PROMOTION')
                "
              >
                <v-list-item-action> </v-list-item-action>
                <v-list-item-title>{{ $t('simple_promotions') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/marketing/coupons')"
                  v-if="$auth.ensure('MARKETING_COUPON_LIST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('coupons') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/promotion-config-2-v2')"
                  v-if="$auth.ensure('PROMOTION_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>
                  {{ $t('dead_stock_promotion_config') | capitalize() }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/marketing/alert-price-parts')"
                  v-if="$auth.ensure('ALERT_PRICE_PARTS')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('parts_price_alerts') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-group
                prepend-icon="send"
                v-model="menu.email_sms"
                v-if="
                $auth.ensure('EMAIL_CONFIG') ||
                $auth.ensure('SMS_CONFIG') ||
                $auth.ensure('MANAGE_MANUAL_CAMPAIGN') ||
                $auth.ensure('CUSTOM_CAMPAIGN') ||
                $auth.ensure('PENDING_CAMPAIGN')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('crm_email_sms') | capitalize() }}</v-list-item-title>
              </template>


              <v-list-item
                  @click="$router.push('/marketing/email-config')"
                  v-if="$auth.ensure('EMAIL_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('email_configuration') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/marketing/sms-config')"
                  v-if="$auth.ensure('SMS_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('sms_configuration') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/marketing/sms-conversations')"
                  v-if="$auth.ensure('SMS_CONVERSATIONS')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('conversational_sms') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/pending-campaign')"
                  v-if="$auth.ensure('PENDING_CAMPAIGN')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('pending_campaigns') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/pending-manual-campaign')"
                  v-if="$auth.ensure('CUSTOM_CAMPAIGN')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('manual_campaign_management') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/contacts-campaigns')"
                  v-if="$auth.ensure('CUSTOM_CAMPAIGN')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title
                >{{ $t('manual_campaign_contacts') | capitalize() }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item @click="$router.push('/unsubscribed-report-config')"
                           v-if="$auth.ensure('CONTACT_UNSUB_REPORT_CONFIG')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('gdpr_unsubscription') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item
                @click="$router.push('/stock-list-v2')"
                v-if="$auth.ensure('STOCK_LIST')"
            >
              <v-list-item-action>
                <v-icon>all_inbox</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('stock_info') | capitalize() }}</v-list-item-title>
            </v-list-item>

            <v-list-group
                prepend-icon="cloud_download"
                v-model="menu.import"
                v-if="
                $auth.ensure('TEST_IMPORT') || $auth.ensure('TEST_STOCK_IMPORT')
                || $auth.ensure('CONFIG_IMPORT')
                || $auth.ensure('CONFIG_STOCK')
                || $auth.ensure('CONFIG_MECA_PLANNING')
                || $auth.ensure('STATISTIQUE_INTERFACE')
                || $auth.ensure('STOCK_QUALIFICATION')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('interface') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item
                  @click="$router.push('/test-import')"
                  v-if="$auth.ensure('TEST_IMPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('vn_vo_test') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/test-import-grc')"
                  v-if="$auth.ensure('TEST_IMPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('stop_mails_sms_test') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/mecaplannig')"
                  v-if="$auth.ensure('CONFIG_MECAPLANNING')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('mecaplanning') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/test-stock-import-v2')"
                  v-if="$auth.ensure('TEST_STOCK_IMPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('stock_test') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/mapping-import')"
                  v-if="$auth.ensure('TEST_STOCK_IMPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('stock_mapping') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/config-import')"
                  v-if="$auth.ensure('CONFIG_IMPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('vn_vo_configuration') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/config-import-grc')"
                  v-if="$auth.ensure('CONFIG_IMPORT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('stop_mails_sms_configuration') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/config-stock-v2')"
                  v-if="$auth.ensure('CONFIG_STOCK')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('stock_configuration') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/stock/list-stock-qualification-configs')"
                  v-if="$auth.ensure('STOCK_QUALIFICATION')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('stock_qualification') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/reports/qualification')"
                  v-if="$auth.ensure('STOCK_QUALIFICATION')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('stock_qualification_report') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/config-meca-planning')"
                  v-if="$auth.ensure('CONFIG_MECA_PLANNING')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title
                >{{ $t('mecaplanning_configuration') | capitalize() }}</v-list-item-title
                >
              </v-list-item>
              <v-list-item
                  @click="$router.push('/satatistique-interface')"
                  v-if="$auth.ensure('STATISTIQUE_INTERFACE')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('interface_status') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/shop-interface')"
                  v-if="$auth.ensure('SHOP_INTERFACE')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('interface_status_per_shop') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
                prepend-icon="mdi-content-save-cog-outline"
                v-model="menu.content"
                v-if="
                $auth.ensure('MANAGE_CONTENT_PRESENTATION') ||
                $auth.ensure('MANAGE_CONTENT_FOOTER') ||
                $auth.ensure('MANAGE_FILES') ||
                $auth.ensure('MANAGE_CAROUSEL') ||
                $auth.ensure('MANAGE_BANNER') ||
                $auth.ensure('MANAGE_PRODUCT_HP') ||
                $auth.ensure('MANAGE_CMS_PAGE') ||
                $auth.ensure('MANAGE_BLOG')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('site_content') | capitalize() }}</v-list-item-title>
              </template>
              <v-list-item
                  @click="$router.push('/content/menu')"
                  v-if="$auth.ensure('MANAGE_CONTENT_MENU')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('menu') | capitalize() }}</v-list-item-title>
              </v-list-item>

              <v-list-item
                  @click="$router.push('/content/carousel')"
                  v-if="$auth.ensure('MANAGE_CAROUSEL')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('carousel') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/presentation')"
                  v-if="$auth.ensure('MANAGE_CONTENT_PRESENTATION')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('homepage_content') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/product')"
                  v-if="$auth.ensure('MANAGE_PRODUCT_HP')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('homepage_products') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/banner')"
                  v-if="$auth.ensure('MANAGE_BANNER')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('banner_model') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/footer')"
                  v-if="$auth.ensure('MANAGE_CONTENT_FOOTER')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('footer') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/meta')"
                  v-if="$auth.ensure('MANAGE_SEO_META')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('meta_configuration') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/popular-vehicles')"
                  v-if="$auth.ensure('MANAGE_POPULAR_VEHICLES')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('vehicle_display') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/cms')"
                  v-if="$auth.ensure('MANAGE_CMS_PAGE')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('cms_page') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/blog')"
                  v-if="$auth.ensure('MANAGE_BLOG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('blog') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/family-infos')"
                  v-if="$auth.ensure('MANAGE_FAMILY_INFOS')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('family_page_content') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/manage-files')"
                  v-if="$auth.ensure('MANAGE_FILES')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('file_management') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/content/carrier')"
                  v-if="$auth.ensure('CONFIG_CARRIER')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('carrier') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
                prepend-icon="shopping_cart"
                v-model="menu.produit"
                v-if="
                $auth.ensure('MAPPING_PRODUCT') ||
                $auth.ensure('SCRAPPING_PRODUCT')||
                $auth.ensure('ACCESSORY_PRODUCT')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('product_catalog') | capitalize() }}</v-list-item-title>
              </template>
              <v-list-item @click="$router.push('/product/accessory-product-list')" v-if="$auth.ensure('ACCESSORY_PRODUCT')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('accessory_products') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/product/categories')" v-if="$auth.ensure('PRODUCT_CATEGORIES')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('category_management') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/product/suppliers-list')"
                  v-if="$auth.ensure('MANAGE_SUPPLIER')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('supplier_management') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/category/supplier-category-model-mapping')"
                  v-if="$auth.ensure('MAPPING_SUPPLIER_CATEGORY_MODEL')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('supplier_model_mapping') | capitalize() }}</v-list-item-title>
              </v-list-item>
               <v-list-item
                  @click="$router.push('/category/product-category-model-mapping')"
                  v-if="$auth.ensure('MAPPING_PRODUCT_CATEGORY_MODEL')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('category_mapping') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/product/mapping-categories')"
                  v-if="$auth.ensure('MAPPING_PRODUCT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('category_assignment_rules') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/product/mapping-product-list')"
                  v-if="$auth.ensure('MAPPING_PRODUCT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('product_mapping') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/product/list-product-scrapper')"
                  v-if="$auth.ensure('SCRAPPING_PRODUCT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('scrapped_products') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/product/order-product-list')"
                  v-if="$auth.ensure('PRIORITY_PRODUCT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('product_order') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/unknown-products')"
                  v-if="$auth.ensure('MANAGE_UNKNOWN_PRODUCT')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('unknown_products') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/product/product-specific-content')" v-if="$auth.ensure('MANAGE_SUPPLIER')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('specific_product_content') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
              prepend-icon="album"
              v-model="menu.tyres"
              v-if="
              $auth.ensure('TYRE_CONFIG_PRICING') ||
              $auth.ensure('TYRE_MANAGE_PRIORITIES') ||
              $auth.ensure('TYRE_MANAGE_SERVICES') ||
              $auth.ensure('TYRE_BENCHMARK') ||
              $auth.ensure('TYRE_MANAGE_PRIORITIES_HP')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('tires') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item @click="$router.push('/tyres/pricing')" v-if="$auth.ensure('TYRE_CONFIG_PRICING')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('prices') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/tyres/priorities')"
                  v-if="$auth.ensure('TYRE_MANAGE_PRIORITIES')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('priorities') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/tyres/priority-hp')"
                  v-if="$auth.ensure('TYRE_MANAGE_PRIORITIES_HP')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('homepage_priority') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/tyres/mounting')"
                  v-if="$auth.ensure('TYRE_MANAGE_SERVICES')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('mounting') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/tyres/products')"
                  v-if="$auth.ensure('TYRE_PRODUCTS_LIST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('products') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/tyres/brands')"
                  v-if="$auth.ensure('TYRE_BRANDS_LIST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('brands') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/tyres/benchmark')"
                  v-if="$auth.ensure('TYRE_BENCHMARK')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('benchmark') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-group
                prepend-icon="precision_manufacturing"
                v-model="menu.parts"
                v-if="$auth.ensure('PARTS_UPDATE_GENERIC_ELEMENTS')"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('parts') | capitalize() }}</v-list-item-title>
              </template>
              <v-list-item
                  @click="$router.push('/parts/generic_elements')"
                  v-if="$auth.ensure('PARTS_UPDATE_GENERIC_ELEMENTS')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('generic_products') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/parts/products')"
                  v-if="$auth.ensure('PARTS_PRODUCTS_LIST')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('products') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/parts/reference')"
                  v-if="$auth.ensure('PARTS_PRODUCTS_REFERENCE')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('search_by_reference') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
                prepend-icon="account_balance"
                v-model="menu.my_shops"
                v-if="$auth.ensure('LIST_SHOPS_B2B')"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('my_shops') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item @click="$router.push('/b2b/shops')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('list') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-group
                prepend-icon="conveyor_belt"
                v-model="menu.achat"
                v-if="$auth.ensure('SUPPLIER_QUALITY_CONFIG') || $auth.ensure('SUPPLIER_PRICES_CONFIG')"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('purchase_settings') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item
                  @click="$router.push('/config-suppliers')"
                  v-if="$auth.ensure('SUPPLIER_QUALITY_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('suppliers') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/config-customer-type')"
                  v-if="$auth.ensure('CUSTOMER_TYPE_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('customer_type') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                  @click="$router.push('/config-suppliers-prices')"
                  v-if="$auth.ensure('SUPPLIER_PRICES_CONFIG')"
              >
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('selling_price') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-group
                prepend-icon="shopping_cart"
                v-if="
                $auth.ensure('BUY_PARTS') ||
                $auth.ensure('MANAGE_BUY_PARTS')
              "
            >
              <template v-slot:activator>
                <v-list-item-title>{{ $t('breakdown') | capitalize() }}</v-list-item-title>
              </template>

              <v-list-item @click="$router.push('/breakdown/buy-product')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('purchase_breakdown') | capitalize() }}</v-list-item-title>
              </v-list-item>
              <v-list-item @click="$router.push('/breakdown/follow-orders')">
                <v-list-item-action></v-list-item-action>
                <v-list-item-title>{{ $t('order_tracking') | capitalize() }}</v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-item
                @click="$router.push('/onboarding-form')"
                v-if="$auth.ensure('NISSAN_ONBOARDING')"
            >
              <v-list-item-action>
                <v-icon>receipt</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('order_tracking') | capitalize() }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="$router.push('/onboarding-kia-form')"
                v-if="$auth.ensure('KIA_ONBOARDING')"
            >
              <v-list-item-action>
                <v-icon>receipt</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('order_tracking') | capitalize() }}</v-list-item-title>
            </v-list-item>
            <v-list-item
                @click="$router.push('/onboarding-suzuki-form')"
                v-if="$auth.ensure('SUZUKI_ONBOARDING')"
            >
              <v-list-item-action>
                <v-icon>receipt</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('order_tracking') | capitalize() }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$router.push('/fitting-time-config')" v-if="$auth.ensure('FITTING_CONFIG')">
              <v-list-item-icon>
                <v-icon>build</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('fitting_time') | capitalize() }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                @click="$router.push('/product/price-file-upload')"
                v-if="$auth.ensure('IMPORT_PRICE_DOCUMENT')"
            >
              <v-list-item-action>
                <v-icon>send</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ $t('fitting_time') | capitalize() }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-app-bar :color="toolbarColor" app fixed clipped-left  >
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'" ></v-app-bar-nav-icon>

          <v-toolbar-title class="white--text">WYZ ACCESS BOX Cockpit</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog
          v-model="redirectDialog"
          width="40%"
          v-if="authorizedShops.filter(el => el.is_principal).length && authorizedShops.filter(el => el.is_principal).length > 1 && $auth.ensure('GOTO_CATALOGUE_ACCESS')"
          
          >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on"  class="ml-1"
           :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
          >
            <v-icon>assistant_direction</v-icon> {{ $t('catalog_access') | capitalize() }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title> {{ $t('catalog_access') | capitalize() }}:</v-card-title>
          <v-card-text :key="i" v-for="(to, i) in authorizedShops.filter(el => el.is_principal)">
            <a
              :href="`https://${to.domain_ssl}`"
              style="display: flex; text-decoration: none"
              target="_blank"
            >
            {{ to.name }}
          </a>
          </v-card-text>
          </v-card>
          </v-dialog>
          <v-btn v-if="$auth.ensure('GOTO_CATALOGUE_ACCESS') && (authorizedShops.filter(el => el.is_principal).length == 1 || loading)"  
           class="ml-1"
           :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
           :disabled="loading"
          >
          <a target="_blank" :href="!loading && `https://${authorizedShops.filter(el => el.is_principal)[0].domain_ssl}`" style="text-decoration: none"><v-icon>assistant_direction</v-icon>  {{ $t('catalog_access') | capitalize() }} </a>
          </v-btn>
          <v-spacer></v-spacer>

          <alerts></alerts>
            <!--notifications-->

            <router-link
                to="/configuration"
                style="display: flex; text-decoration: none"
                v-if="$auth.ensure('CONFIGURATION')"
            >
              <v-btn
                  class="ml-1"
                  small
                  fab
                  :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
              >
                <v-icon large>settings</v-icon>
              </v-btn>
            </router-link>
            <router-link
                to="/help"
                style="display: flex; text-decoration: none"
            >
              <v-btn
                  class="ml-1"
                  small
                  fab
                  :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
              >
                <v-icon large>help_outline</v-icon>
              </v-btn>
            </router-link>
            <div>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ml-1"
                  small
                  fab
                  :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
                  v-bind="attrs"
                  v-on="on"
              >
                {{ currentLanguage }}
              </v-btn>

              </template>
              <v-list>
                <v-list-item @click="changeLanguage('en')">
                  <v-list-item-title>{{ "en" }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeLanguage('fr')">
                  <v-list-item-title>{{ "fr" }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            </div>
            <div class="cart-container">
              <v-btn
                  class="ml-1"
                  small
                  fab
                  :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
                  @click="toggleDialog"
              >
                <v-icon>shopping_cart</v-icon>
              </v-btn>
              <span class="cart-quantity">{{getCartQuantity}}</span>
            </div>
            <router-link
                to="/user/account"
                style="display: flex; text-decoration: none"
            >
              <v-btn
                  class="ml-1"
                  small
                  fab
                  :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
              >
                <v-icon large>account_circle</v-icon>
              </v-btn>
            </router-link>
            <v-btn
                class="ml-1"
                small
                fab
                :color="$auth.ensure('DARK_MODE') ? 'black' : 'white'"
                @click="logoutUser()"
            >
              <v-icon dark>input</v-icon>
            </v-btn>
        </v-app-bar>

      </div>
      <v-dialog v-model="dialog" @click.native.stop>
        <cart-details v-if="$auth.ensure('BUY_PARTS') || $auth.ensure('MANAGE_BUY_PARTS')" @toggleDialog="toggleDialog"></cart-details>
      </v-dialog>

      <v-main class="main-content">
        <router-view></router-view>
      </v-main>
      <v-footer class="pa-3" app>
        <v-spacer></v-spacer>
        <div>Copyright Spid-tech &copy; {{ new Date().getFullYear() }}</div>
      </v-footer>
    </template>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import StripeAlert from "@/components/stripe/StripeAlert.vue";
import MandateAlert from "@/components/billing/MandateAlert.vue";
import TyreAlert from "@/components/nissan/TyreAlert.vue";
import LOGOUT from "./graphql/user/logout.gql";
import CompleteProfileAlert from "@/components/users/CompleteProfilAlert.vue";
import alerts from "./components/alert_messagerie/alerts.vue";
import CartDetails from "@/components/breakdown/CartDetails.vue";
import moment                   from 'moment'
export default {
  components: { MandateAlert, StripeAlert, CompleteProfileAlert, TyreAlert, alerts, CartDetails },
  computed: {
    ...mapGetters({
      isUserConnected: "getConnectedUser",
      getCartQuantity: "getCartQuantity",
      authorizedShops:"getShops",
    }),
    currentLanguage() {
      return this.$i18n.locale;
    },

    loading (){ return (!this.authorizedShops.filter(el => el.is_principal) || !this.authorizedShops.filter(el => el.is_principal).length)},
    toolbarColor() {
      return process.env.NODE_ENV === "production" ? "primary" : "#f9a602";
    },
    token(){
      return localStorage.getItem('nuxt_token');
    }
  },
  methods: {
    ...mapMutations({
      setUtils: "setUtils",
    }),
    ...mapActions({
      logout: "logout",
      getNuxtToken:"getNuxtToken",
      initAuthorizedData: "getAuthorizedData",
    }),
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem('lang', lang);
      window.location.reload();
    },

    toggleDialog() {
      this.dialog = !this.dialog;
    },
    getShopUrl(shop) {
      return this.$store.state.auth.user.access.match(/b2b/i)
          ? `https://${shop.domain_ssl}/auth?token=${this.token}`
          : `https://${shop.domain_ssl}/`
    },
    async logoutUser() {
      try {
        await this.$apollo.mutate({
          mutation: LOGOUT,
        });
      } catch (err) {
        console.log(err.message);
      }
      this.logout();
    },
    isTokenValid(){
      if(!localStorage.getItem('nuxt-token-expire-at')
      || moment(localStorage.getItem('nuxt-token-expire-at')).isBefore(moment())
      || !localStorage.getItem('nuxt_token') ){
      return false;
      }
      return true;
    },
    async generateNuxtToken() {
        if(this.isTokenValid()){
          return;
        }
       await this.getNuxtToken()
      }
  },
  created() {
    this.setUtils({ name: "apollo", value: this.$apollo });
    this.setUtils({ name: "router", value: this.$router });
  },
  async mounted() {
    if (this.isUserConnected) {
      await this.initAuthorizedData();
      setInterval(async () => {
        await this.generateNuxtToken();
    }, 30*60*1000);
    }
  },
  data() {
    return {
      drawer: null,
      dialog: false,
      redirectDialog:false,
      viewNotifications: false,
      menu: {
        reports: false,
        commande: false,
        import: false,
        stock: false,
        nissan: false,
        campaign: false,
        billing: false,
        shops: false,
        my_shops: false,
        marketing: false,
        seo: false,
        tyres: false,
        content: false,
        email_sms:false,
      },
    };
  },
};
</script>

<style>
.cart-container {
  position: relative;
  display: flex;
}
.cart-container span.cart-quantity {
  position: absolute;
  top: 60%;
  right: 0px;
  margin-right: -5px;
  background: #000;
  color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 11px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
@page {
  size: A4;
  margin: 0;
}
@media print {
  .main-nav-drawer,
  .main-toolbar,
  .v-btn {
    display: none;
  }
  .main-content {
    padding: 0 !important;
  }
  html,
  body {
    width: 315mm;
    height: 446mm;
  }
}
</style>